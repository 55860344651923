import banner from "./assets/banner.png";
import logo from "./assets/logo.png";
import checkPng from "./assets/check.png";
import loadingGif from "./assets/giphy.webp";
import gif from "./assets/bugbearz.gif";
import Faq from "react-faq-component";

import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { Snackbar, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useState, useCallback, useEffect, useMemo } from "react";
import { sign } from "tweetnacl";
import bs58 from "bs58";
import axios from "axios";
import clsx from "clsx";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import { Swiper, SwiperSlide } from "swiper/react";
import validBugBearz from "./bugbearz.json";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "Launch will be on which blockchain?",
      content: "Solana Blockchain",
    },
    {
      title: "What’s the price for Bugbearz ?",
      content: "You can get your own BugBear for free !",
    },
    {
      title: "How much supply ?",
      content: "Only 555 Exclusive BugBearz on Solana Metaverse!",
    },
    {
      title: "What makes BugBearz special ?",
      content: `Everything! BugBearz artist not hired/payed artist as rest of the %95 other NFT projects (We respect all artist in world and love them!), this means that Hodlers of BugBearz going to have always orginal content and unique real ART. BugBearz is first step to our Universe. We’re ambitious for NFT world and we’re planning to stay here for long thats why we’re calling our project as BugBearz “Universe”. 555 unique BugBearz holders going to have huge benefits and amazing collections from Universe!
Please check for detailed information on our Roadmap!`,
    },
  ],
};

export default function Home(props) {
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [signMessage, setSignMessage] = useState({
    show: false,
    loading: false,
  });
  const [activateTool, setActivateTool] = useState({
    wallet: "",
    key: "",
  });
  const [holdingBugBearz, setHoldingBugBearz] = useState({
    data: [],
    done: false,
    isHoldingEnough: false,
  });

  const wallet = useWallet();

  async function initBugBearz() {
    const nfts = (
      await getParsedNftAccountsByOwner({
        publicAddress: wallet.publicKey,
      })
    )
      .filter((nft) => {
        if (
          nft.updateAuthority ===
            "aqF4fCbLeHAt3QDp9JsjyQKJciWDHocQ3hQghwfFN3g" &&
          validBugBearz.includes(nft.mint)
        ) {
          return true;
        }
        return false;
      })
      .map((bugBear) => {
        return {
          ...bugBear,
          data: {
            ...bugBear.data,
            number: Number(bugBear.data.name.split(" ")[1].replace("#", "")),
          },
        };
      })
      .sort((a, b) => a.data.number - b.data.number);
    setHoldingBugBearz({
      ...holdingBugBearz,
      data: nfts,
      done: true,
      isHoldingEnough: nfts.length >= 1,
    });
  }

  const bearzCheck = useCallback(async () => {
    const bearzFiltered = holdingBugBearz.data.filter((nft) => {
      if (nft.data.imageUrl) {
        return false;
      }
      return true;
    });

    for (const bear of bearzFiltered) {
      const getMetaData = await axios(bear.data.uri);

      if (getMetaData && getMetaData.data && getMetaData.data.image) {
        const findIndex = holdingBugBearz.data.findIndex(
          (holdingBear) => holdingBear.data.name === bear.data.name
        );
        //console.log({ findIndex, holdingBugBearz });
        if (findIndex >= 0) {
          setHoldingBugBearz(
            (() => {
              let holdingBugBearzCopy = { ...holdingBugBearz };
              holdingBugBearzCopy.data[findIndex].data.imageUrl =
                getMetaData.data.image;
              return holdingBugBearzCopy;
            })()
          );
        }
      }
      await new Promise((res) => setTimeout(res, 500));
    }
  }, [holdingBugBearz.data]);

  useEffect(() => {
    if (holdingBugBearz.done) {
      bearzCheck();
    }
  }, [holdingBugBearz.done]);

  useEffect(() => {
    if (wallet.connected) {
      setSignMessage({
        ...signMessage,
        show: true,
      });
      initBugBearz();
    }
  }, [wallet.connected]);

  const signMessageAndSendRequest = async () => {
    setSignMessage({ ...signMessage, loading: true });
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!wallet.publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!wallet.signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      const message = new TextEncoder().encode("OUGA OUGA!");

      // Sign the bytes using the wallet
      const signature = await wallet.signMessage(message);

      // Verify that the bytes were signed using the private key that matches the known public key
      const verifySignature = sign.detached.verify(
        message,
        signature,
        wallet.publicKey.toBytes()
      );

      if (verifySignature) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/verify`,
          {
            wallet: wallet.publicKey.toBase58(),
            signature: bs58.encode(signature),
          }
        );

        console.log({ response });

        if (
          response &&
          response.data &&
          response.data.key &&
          response.data.wallet
        ) {
          setSignMessage({ ...signMessage, loading: false });
          setActivateTool({
            wallet: response.data.wallet,
            key: response.data.key,
          });
        }

        setAlertState({
          open: true,
          message: `Signing Succesfull`,
          severity: "success",
        });
        return;
      }
      throw new Error("Invalid signature!");
    } catch (error) {
      setSignMessage({ ...signMessage, loading: false });
      if (error.isAxiosError) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return setAlertState({
            open: true,
            message: `Signing failed: ${error.response.data.message}`,
            severity: "error",
          });
        }
      }
      setAlertState({
        open: true,
        message: `Signing failed: ${error?.message}`,
        severity: "error",
      });
    }
  };

  return (
    <div className="app">
      <header>
        <div className="header-top">
          <span className="logo-wrapper">
            <img src={logo} className="logo" alt="logo" />
          </span>
        </div>
        <div className="header-down">
          <div className="links">
            <a
              className="discord"
              href="https://discord.gg/ft5u4qyARN"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path d="M25.12,6.946c-2.424-1.948-6.257-2.278-6.419-2.292c-0.256-0.022-0.499,0.123-0.604,0.357 c-0.004,0.008-0.218,0.629-0.425,1.228c2.817,0.493,4.731,1.587,4.833,1.647c0.478,0.278,0.638,0.891,0.359,1.368 C22.679,9.572,22.344,9.75,22,9.75c-0.171,0-0.343-0.043-0.501-0.135C21.471,9.598,18.663,8,15.002,8 C11.34,8,8.531,9.599,8.503,9.615C8.026,9.892,7.414,9.729,7.137,9.251C6.86,8.775,7.021,8.164,7.497,7.886 c0.102-0.06,2.023-1.158,4.848-1.65c-0.218-0.606-0.438-1.217-0.442-1.225c-0.105-0.235-0.348-0.383-0.604-0.357 c-0.162,0.013-3.995,0.343-6.451,2.318C3.564,8.158,1,15.092,1,21.087c0,0.106,0.027,0.209,0.08,0.301 c1.771,3.11,6.599,3.924,7.699,3.959c0.007,0.001,0.013,0.001,0.019,0.001c0.194,0,0.377-0.093,0.492-0.25l1.19-1.612 c-2.61-0.629-3.99-1.618-4.073-1.679c-0.444-0.327-0.54-0.953-0.213-1.398c0.326-0.443,0.95-0.541,1.394-0.216 C7.625,20.217,10.172,22,15,22c4.847,0,7.387-1.79,7.412-1.808c0.444-0.322,1.07-0.225,1.395,0.221 c0.324,0.444,0.23,1.066-0.212,1.392c-0.083,0.061-1.456,1.048-4.06,1.677l1.175,1.615c0.115,0.158,0.298,0.25,0.492,0.25 c0.007,0,0.013,0,0.019-0.001c1.101-0.035,5.929-0.849,7.699-3.959c0.053-0.092,0.08-0.195,0.08-0.301 C29,15.092,26.436,8.158,25.12,6.946z M11,19c-1.105,0-2-1.119-2-2.5S9.895,14,11,14s2,1.119,2,2.5S12.105,19,11,19z M19,19 c-1.105,0-2-1.119-2-2.5s0.895-2.5,2-2.5s2,1.119,2,2.5S20.105,19,19,19z"></path>
              </svg>
            </a>
            <a
              className="twitter"
              href="https://twitter.com/Bug_Bearz"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <main className="container-lg">
        <div className="banner-wrapper">
          <span>
            <img className="banner" src={banner} alt="banner"></img>
            {/* <img
              className="coming-soon"
              src={comingSoonV2}
              alt="coming soon..."
            ></img> */}
          </span>
        </div>
        <div className="description-wrapper">
          <img src={gif} alt="BugBearz" className="bugbearz" />
          <p>
            Exclusive Collection of 555 Biggest And Strongest Goblinoids Living
            On Solana Blockchain, BearzDAO, Merchandise, BugBearz Ecosystem and
            much more, BugBearz Have Only Two Genuine Goals In Life, Food And
            Treasure!
            <div className="me-buy-btn-wrapper">
              <a
                className="magiceden-button"
                href="https://www.magiceden.io/marketplace/bugbearz"
                target="_blank"
                rel="noreferrer"
              >
                <span>BUY ON MAGICEDEN</span>
              </a>
            </div>
          </p>
        </div>
        <div className="tool-access-wrapper">
          <h4 className="main-title">GET ACCESS TO THE TOOL</h4>
          <div className="step-1">
            <h6 className="title">
              1- CONNECT WALLET
              {wallet.connected && (
                <img className="checked" src={checkPng} alt="checked" />
              )}
            </h6>
            {wallet.connected ? (
              <>
                <span>{wallet.publicKey.toBase58()}</span>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  freeMode={true}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="bugbearz-slider"
                  centerInsufficientSlides={true}
                >
                  {holdingBugBearz.data.map((bugbear) => {
                    return (
                      <SwiperSlide>
                        <div className="bugbearz-card">
                          <div className="img">
                            <img
                              src={bugbear.data.imageUrl || loadingGif}
                              alt="bugbear"
                            />
                          </div>
                          <div className="name">{bugbear.data.name || ""}</div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            ) : (
              <WalletDialogButton className="my-btn" />
            )}
          </div>
          {signMessage.show && holdingBugBearz.done && (
            <>
              {holdingBugBearz.isHoldingEnough ? (
                <div className="step-2">
                  <h6 className="title">2- SIGN MESSAGE</h6>
                  <div
                    className={clsx({
                      "my-btn": true,
                      disabled: signMessage.loading,
                    })}
                    onClick={signMessageAndSendRequest}
                  >
                    {signMessage.loading ? (
                      <CircularProgress style={{ width: 25, height: 25 }} />
                    ) : (
                      <span>SIGN</span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="not-enough-bugbearz">
                  <div>You need at least 1 bugbearz</div>
                  <div className="me-buy-btn-wrapper">
                    <a
                      className="magiceden-button"
                      href="https://www.magiceden.io/marketplace/bugbearz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>BUY ON MAGICEDEN</span>
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
          {activateTool.key && activateTool.wallet && (
            <div className="step-3">
              <h6 className="title">3- CLICK THE ACTIVATE BUTTON ON ME</h6>
              <a
                className="magiceden-button"
                href={`https://www.magiceden.io/marketplace/bugbearz?activateTool=true&key=${activateTool.key}&wallet=${activateTool.wallet}`}
                target="_blank"
                rel="noreferrer"
              >
                GO TO MAGICEDEN
              </a>
            </div>
          )}
        </div>

        <div className="faq-wrapper">
          <Faq
            data={data}
            styles={{
              rowContentPaddingTop: "10px",
              rowContentPaddingBottom: "10px",
              rowContentPaddingRight: "10px",
            }}
          />
        </div>
      </main>
      <footer>
        <span className="footer-title">JOIN US</span>
        <div className="links">
          <a
            className="discord"
            href="https://discord.gg/ft5u4qyARN"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path d="M25.12,6.946c-2.424-1.948-6.257-2.278-6.419-2.292c-0.256-0.022-0.499,0.123-0.604,0.357 c-0.004,0.008-0.218,0.629-0.425,1.228c2.817,0.493,4.731,1.587,4.833,1.647c0.478,0.278,0.638,0.891,0.359,1.368 C22.679,9.572,22.344,9.75,22,9.75c-0.171,0-0.343-0.043-0.501-0.135C21.471,9.598,18.663,8,15.002,8 C11.34,8,8.531,9.599,8.503,9.615C8.026,9.892,7.414,9.729,7.137,9.251C6.86,8.775,7.021,8.164,7.497,7.886 c0.102-0.06,2.023-1.158,4.848-1.65c-0.218-0.606-0.438-1.217-0.442-1.225c-0.105-0.235-0.348-0.383-0.604-0.357 c-0.162,0.013-3.995,0.343-6.451,2.318C3.564,8.158,1,15.092,1,21.087c0,0.106,0.027,0.209,0.08,0.301 c1.771,3.11,6.599,3.924,7.699,3.959c0.007,0.001,0.013,0.001,0.019,0.001c0.194,0,0.377-0.093,0.492-0.25l1.19-1.612 c-2.61-0.629-3.99-1.618-4.073-1.679c-0.444-0.327-0.54-0.953-0.213-1.398c0.326-0.443,0.95-0.541,1.394-0.216 C7.625,20.217,10.172,22,15,22c4.847,0,7.387-1.79,7.412-1.808c0.444-0.322,1.07-0.225,1.395,0.221 c0.324,0.444,0.23,1.066-0.212,1.392c-0.083,0.061-1.456,1.048-4.06,1.677l1.175,1.615c0.115,0.158,0.298,0.25,0.492,0.25 c0.007,0,0.013,0,0.019-0.001c1.101-0.035,5.929-0.849,7.699-3.959c0.053-0.092,0.08-0.195,0.08-0.301 C29,15.092,26.436,8.158,25.12,6.946z M11,19c-1.105,0-2-1.119-2-2.5S9.895,14,11,14s2,1.119,2,2.5S12.105,19,11,19z M19,19 c-1.105,0-2-1.119-2-2.5s0.895-2.5,2-2.5s2,1.119,2,2.5S20.105,19,19,19z"></path>
            </svg>
          </a>
          <a
            className="twitter"
            href="https://twitter.com/Bug_Bearz"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
            </svg>
          </a>
        </div>
      </footer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={5000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
